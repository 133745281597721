.deviceTableHeader {
  display: flow-root;
}

.deviceTableHeadings {
  display: inline-block;
}

.deviceTableHeadings.right {
  float: right;
  text-align: right;
}
