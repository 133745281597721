.addButton {
  margin-bottom: 15px;
}

.row {
  display: flex;
}
.col {
  flex: 50%;
}
