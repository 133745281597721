
.monitoredPatientsChart {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.monitoredPatientsChart h4 {
  margin: 0;
}

.chartWrapper {
  width: 90%;
  height: 100%;
}
