.menu {
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  /* font-weight: 500; */
  padding: 5px 0 !important;
  overflow-y: auto;
}

.item {
  line-height: 15px !important;
  padding: 7px !important;
  border-radius: 0 !important;
}

.item, .item .primaryText, .item .secondaryText {
  display: block !important;
}

.subMenu {
  border-bottom: 1px solid #CED9E0;
  padding: 0 !important;
  padding-left: 15px !important;
}

.openMenuHeader {
  background-color: rgba(115, 134, 148, 0.1);
}
