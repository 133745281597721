.userLabel {
  padding: 5px 10px;
}
.topBar {
  min-height: 50px !important; /* otherwise will break on IE */
}

.topBar .topBarHeading {
  font-weight: 600 !important;
}

.logo {
  height: 70%;
  margin-right: 7px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
