.addButton {
  margin-bottom: 15px;
}

.row {
  display: flex;
}
.col {
  flex: 50%;
}

.patientsTable {
  width: 0;
  flex: 1 1 50%;
}

.patientsTable {
  width: 100%;
}

.patientsTable tbody td {
  vertical-align: middle !important;
}

