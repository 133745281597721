
.dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.firstRow, .secondRow {
  padding: 5px;
}

.firstRow {
  height: 400px;
  min-height: 210px;
  padding-bottom: 0;
  flex: 0 1 auto;
}

.secondRow {
  height: 600px;
  padding-top: 0;
  flex: 1 1 auto;
}

.firstColumn {
  width: 0;
  flex: 1 1 50%;
}

.secondColumn {
  width: 0;
  flex: 1 1 auto;
  max-width: 425px;
}

.requestCards {
  align-items: center;
  padding-top: 10px;
}
