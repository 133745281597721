.report {
  margin: 20px;
}

.report table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}

.report table td, .report table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.report table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0f9960;
  color: white;
}