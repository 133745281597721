
.activePatientsChart {
  width: 0;
  flex: 1 1 50%;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-items: baseline;
  vertical-align: center;
}

.header h4 {
  flex: 0 1 auto;
}

.chartControlButtons {
  flex: 1 1 auto;
  text-align: right;
  margin-top: -3px;
}

.chartWrapper {
  width: 100%;
  height: 80%;
}

