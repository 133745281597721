.menu {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 0 !important;
  font-weight: 500;
  padding: 5px 0 !important;
}

.item {
  border-radius: 0 !important;
}

ul.subMenu {
  padding: 0;
  padding-bottom: 5px;
}

.subMenu .item {
  padding-left: 30px !important;
}
