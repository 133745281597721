.itemText {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 0px;
  margin: 10px 30px 0 0 !important;
  width: 100px;
  white-space: nowrap;
}

.status {
  margin-left: 10px;
  margin-right: 5px
}

.header {
  display: inline-block;
}

.item {
  padding: 5px;
  margin-left: -5px;
}

.itemText {
  display: inline-block;
  margin-bottom: 0px !important;
  width: 120px;
  white-space: nowrap;
}

.striped:nth-child(odd) {
  background: rgba(191, 204, 214, 0.15)
}
