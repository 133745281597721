.patient {
  padding: 15px 10px;
  width: 100%;
}

.packsListItem {
  width: 50%;
  padding: 10px;
}

.packsListItem:nth-child(2n+1):last-child {
  width: 100%;
}


.personalInfo {
  flex: 0;
  padding-left: 0;
}

.infoColumn {
  flex: 1 1 auto;
  min-width: 200px;
  max-width: 400px;
}

.packColumn {
  flex: 1 1 auto;
  margin-top: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.adherenceScore {
  width: 80%;
  height: 300px;
  margin: auto;

}
